<template>
  <div>
    <!--table depatment-->
    <ek-table
      :columns="columns"
      :items="branches"
      @delete-selected="deleteUsers"
      @details="detailsAppPanel"
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        <span> {{ new Date(value).toLocaleDateString() }}</span>
      </template>
      <template slot="items.facultyId" slot-scope="{ props }">
        <span v-if="facultyName.find((u) => u.id == props.row.facultyId)">
          {{ facultyName.find((u) => u.id == props.row.facultyId).name }}
        </span>
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      columns: (state) => state.branches.columns,
      facultiesList: (state) => state.faculty.facultyName,
      facultyName: (state) => state.faculty.facultyName,
    }),
    ...mapGetters(["branches"]),
  },
  methods: {
    ...mapActions(["getBranchList", "getFacultyName"]),
    detailsAppPanel({ row }) {
      this.$store.commit("IS_DIALOG_OPEN", true);
      this.$store.commit("SET_BRANCH_DTO", row);
    },
    deleteUsers(e) {
      this.$store.dispatch("deleteDepartments", e);
    },
  },
  created() {
    this.getBranchList();
    this.getFacultyName();
  },
};
</script>
